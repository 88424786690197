$(document).ready(function () {


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * sticky header
	// *
	// *
	$(window).scroll(function () {
		if ($(window).scrollTop() >= 40) {
			$(".header").addClass("header--collapse");
		} else {
			$(".header").removeClass("header--collapse");
		}
	});



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * parent clickable elements (excludes links inside)
	// *
	// * @set outer parent element class: js-click-item-parent
	// * @set link (<a> tag) element class: js-click-item-link
	// *
	// * This makes the whole element clickable and still
	// * makes other links inside clickable with their target
	// *
	$(".js-click-item-parent").delegate('a', 'click', function (e) {
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		} else {
			window.location = url;
		}
		return false;
	}).click(function () {
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		} else {
			window.location = url;
		}
		return false;
	});


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * Scroll-To
	// *
	// * Smooth-Scroll to targets on page
	// *
	$('a[href*="#"]:not([href="#"]):not([data-ignore-scrollto])').click(function () {
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			if (target.length) {
				scrollTo(target);
			}
		}
	});

	function scrollTo(element) {
		$('html, body').animate({
			scrollTop: element.offset().top - 60
		}, 1000);
	}

	// if hash is on load set then scroll to instead of jump
	var hash = window.location.hash;

	if (hash) {
		setTimeout(function () {
			scrollTo($(hash));
		}, 0);
	}



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * mobile navigation
	// *
	// *
	$('.js-nav-mobile-btn').click(function () {
		$('.js-nav-mobile').toggleClass('active');
		$('html, body').toggleClass('freeze');
	});

	$('.js-nav-mobile .parent span').click(function () {
		$(this).closest('li').toggleClass('is-open is-closed').find('> ul').slideToggle();
	});

	// trigger active menü-parents
	$('.js-nav-mobile .parent.active > div > span').trigger("click");




	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * heroSlider
	// *
	// *
	var $heroSlider = $(".js-hero-slider");

	if ($heroSlider.length) {
		$heroSlider.slick({
			fade: false,
			dots: true,
			arrows: true,
			autoplay: true,
			speed: 1000,
			autoplaySpeed: 6000,
			prevArrow: $(".js-hero-slider-nav-prev"),
			nextArrow: $(".js-hero-slider-nav-next"),
			responsive: [
				{
					breakpoint: 1400,
					settings: {
						dots: false,
						arrows: false,
					}
				},
				{
					breakpoint: 800,
					settings: {
						dots: false,
						arrows: false,
						autoplay: false,
					}
				}
			]
		});
	}

	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * logoCarousel
	// *
	// *
	var $logoCarousel = $(".js-logo-carousel");

	if ($logoCarousel.length) {
		$logoCarousel.slick({
			fade: false,
			dots: false,
			arrows: true,
			autoplay: true,
			infinite: true,
			slidesToShow: 4,
			slidesToScroll: 4,
			speed: 1000,
			autoplaySpeed: 8000,
			prevArrow: $(".js-logo-carousel-nav-prev"),
			nextArrow: $(".js-logo-carousel-nav-next"),
			responsive: [
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						autoplay: false,
					}
				},
				{
					breakpoint: 800,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				}
			]
		});
	}





	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * heroNav
	// *
	// *
	var $navMain = $(".js-hero-nav-main"),
		$navSub = $(".js-hero-nav-sub"),
		$navOverlay = $(".js-hero-nav-overlay");

	$navMain.find(".js-nav-item a").click(function (event) {

		// prevent tab from redirecting to url unless tab is active
		if (!$(this).parent("li").hasClass("active")) {
			event.preventDefault();
		}

		var index = $(this).parents("li").index();

		$(this).parents("li").addClass("active expanded").siblings().removeClass("active expanded");

		$navSub.show();
		$navOverlay.fadeIn();
		$(".js-hero-nav-block").eq(index).show().siblings().hide();
	});

	// close sub nav
	$(".js-hero-nav-close").on("click", function () {
		$navOverlay.fadeOut();
		$navSub.hide();
		$navMain.find("li.active").removeClass("active expanded");
		$navMain.find("li.current").addClass("active");
	});



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * chartsSlider
	// *
	// *
	var $chartsSlider = $(".js-charts-slider");

	if ($chartsSlider.length) {
		$chartsSlider.slick({
			fade: false,
			dots: true,
			arrows: true,
			//autoplay: true,
			speed: 1000,
			autoplaySpeed: 8000,
			prevArrow: $(".js-charts-slider-nav-prev"),
			nextArrow: $(".js-charts-slider-nav-next"),
			responsive: []
		});

		$chartsSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {

			// if next slide is a count up slide
			if ($chartsSlider.find('[data-slick-index="' + nextSlide + '"] .js-countup').length) {
				$(".js-countup").each(function () {
					var counterID = $(this).attr("id"),
						countTo = $(this).data("count");

					if (countTo % 1 === 0) {
						// if number is integer
						decimalPlaces = 0;
					} else {
						// if number is float
						decimalPlaces = 1;
					}

					var count = new CountUp(counterID, 0, countTo, decimalPlaces, 4, {
						decimal: ','
					});
					count.start();
				});
			}

			$chartsSlider.find('[data-slick-index="' + nextSlide + '"] .js-chart-update').trigger("click");

		});
	}


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * countUp (seperateModule)
	// *
	// *
	$(".js-countup").each(function () {
		var counterID = $(this).attr("id"),
			countTo = $(this).data("count");

		if (countTo % 1 === 0) {
			// if number is integer
			decimalPlaces = 0;
		} else {
			// if number is float
			decimalPlaces = 1;
		}

		var count = new CountUp(counterID, 0, countTo, decimalPlaces, 4, {
			decimal: ','
		});
		count.start();
	});




	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * tabContent
	// *
	// *
	var setHeight = 0,
		height = 0;

	// get the height of all tab content
	$(".js-tab-content-item").each(function(){
		var height = $(this).outerHeight();
		if (height >= setHeight) {
			setHeight = height;
		}
	});

	$(".js-tab-content-nav-item").click(function () {
		var index = $(this).index();
		$(this)
			.addClass("tabContent__navItem--active")
			.siblings().removeClass("tabContent__navItem--active")
			.parents(".js-tab-content").find(".js-tab-content-item")
			.addClass("tabContent__item--active").eq(index)
			.siblings().removeClass("tabContent__item--active");

			// set same height for all tab content
		$(".tabContent__item--active").css("min-height", setHeight + "px");

		// check if chart in content and start animation
		$(".js-tab-content").find(".js-chart-update").trigger("click");

	});

	$(".js-tab-content").find(".js-tab-content-nav-item:eq(0)").trigger("click");

	// toggle Content
	$(".js-tab-content-button").click(function () {
		$(this)
			.parents(".js-tab-content-item")
			.find(".js-tab-content-text")
			.slideToggle()
			.parents(".js-tab-content-item")
			.find(".js-tab-content-button")
			.toggle();
	});

	// trigger tab if hash in url
	$(window).on('hashchange load', function () {
		var hash = window.location.hash;
		if (hash) {
			if ($(".js-tab-content-nav-item" + hash).length) {
				$(".js-tab-content-nav-item" + hash).trigger("click");
			}
		}
	});





	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * overlaySlider
	// *
	// *
	var $overlaySlider = $(".js-overlay-slider");

	if ($overlaySlider.length) {
		$overlaySlider.slick({
			fade: false,
			dots: true,
			arrows: false,
			autoplay: false,
			speed: 1000,
			autoplaySpeed: 8000
		});
	}


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * asideDetails
	// *
	// *
	$(".js-aside-details-button").click(function () {
		$(".js-aside-details").fadeToggle();
	});

	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * asideContact
	// *
	// *
	var $asideContactButton = $(".js-aside-contact-button"),
		$asideContact = $(".js-aside-contact");

	if ($asideContact.length) {

		$asideContactButton.click(function () {
			$asideContact.toggleClass("active");
			$asideContact.removeClass("scrollopen");
			$asideContact.removeClass("openonload");
		});

		var scroll = $asideContact.data("aside-contact-scrollopen");

		if (scroll) {
			// calculate distance to scroll
			var distance = (scroll * $(document).height()) / 100;

			$(window).scroll(function () {
				if ($asideContact.hasClass("scrollopen")) {
					if ($(window).scrollTop() >= distance) {
						$asideContact.addClass("active");
					}
				}
			});
		}
	}




	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * asideBookmarks
	// *
	// *
	$(".js-aside-bookmarks-button").click(function () {
		$(".js-aside-inquiry.asideBookmarks--active").find(".js-aside-inquiry-button").trigger("click");
		$(".js-aside-bookmarks").toggleClass("asideBookmarks--active");
	});

	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * asideInquiry
	// *
	// *
	$(".js-aside-inquiry-button").click(function () {
		$(".js-aside-bookmarks.asideBookmarks--active").find(".js-aside-bookmarks-button").trigger("click");
		$(".js-aside-inquiry").toggleClass("asideBookmarks--active");
	});

	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * checkboxToogle
	// *
	// *
	$('.js-checkbox-toggle-parent').on("change", function () {
		var group = $(this).data("checkbox-toggle-parent");

		if ($(this).is(':checked')) {
			$('*[data-checkbox-toggle-group="' + group + '"]').prop("checked", true).change();
		} else {
			//$('*[data-checkbox-toggle-group="' + group + '"]').prop("checked", false).change();
		}
	});

	// uncheck "all"-selector if a child input gets toggled off
	$('input[type=checkbox]').on("change", function () {
		var group = $(this).data("checkbox-toggle-group");

		if (!$(this).is(':checked')) {
			$('*[data-checkbox-toggle-parent="' + group + '"]').prop("checked", false).change();
		}
	});




	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * faq
	// *
	// *
	$(".js-faq-button").click(function () {
		$(this).parents(".js-faq").toggleClass("faq__item--active").find(".js-faq-answer").slideToggle();
	});

	var $faqMoreButton = $(".js-faq-more-button");

	if ($faqMoreButton.length) {
		$faqMoreButton.each(function () {
			var $faqWrapper = $faqMoreButton.parents(".js-faq-wrapper"),
				quantity = $faqWrapper.data("show-items") + 1;
			$faqWrapper.find('.js-faq:nth-child(n+' + quantity + ')').hide();
		})
	};

	$faqMoreButton.click(function () {
		$(this).parents(".js-faq-wrapper").find(".js-faq").show();
		$(this).parents(".js-faq-more-button-wrapper").hide();
	});


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * moreButtonContent
	// *
	// *
	$(".js-more-button-content-button").click(function () {
		$(this)
			.toggleClass("active")
			.parents(".js-more-button-content-wrapper")
			.find(".js-more-button-content")
			.slideToggle();
	});


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * imageTextToggle
	// *
	// *
	$(".js-image-text-toggle-button").click(function () {
		$(this)
			.parents(".js-image-text-toggle")
			.find(".js-image-text-toggle-content")
			.slideToggle()
			.parents(".js-image-text-toggle")
			.find(".js-image-text-toggle-button")
			.toggle();
	});

	$(".js-image-text-toggle").each(function () {
		if ($(this).find(".js-image-text-toggle-content").length <= 0) {
			$(this).find(".js-image-text-toggle-button-wrapper").hide();
		}
	});




	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * teaserSlider
	// *
	// *
	var $teaserSlider = $(".js-teaser-slider");

	if ($teaserSlider.length) {
		$teaserSlider.slick({
			fade: false,
			dots: false,
			arrows: true,
			autoplay: true,
			speed: 1000,
			pauseOnHover: false,
			autoplaySpeed: 8000,
			prevArrow: $(".js-teaser-slider-nav-prev"),
			nextArrow: $(".js-teaser-slider-nav-next"),
			responsive: [
				{
					breakpoint: 800,
					settings: {
						dots: false,
						arrows: false,
						autoplay: false,
					}
				}
			]
		});
	}


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * resourceTeaser - filtering
	// *
	// *
	var $resourceFilter = $('.js-resource-filter-wrapper'),
		tmpFilter = '';
	if ($resourceFilter.length) {
		var mixer = mixitup('.js-resource-filter-wrapper', {
			controls: {
				toggleLogic: 'or'
			},
			selectors: {
				target: '.js-resource-filter-item'
			},
			callbacks: {
				onMixStart: function (state, futureState) {
					if (state.activeFilter.selector == futureState.activeFilter.selector) {
						mixer.filter('all');
					}
				},
				onMixClick: function (state, originalEvent) {
					$resourceFilter.parents(".js-resource-teaser-outer").removeClass("limit").find(".js-resource-teaser-button-wrapper").fadeOut();
				}
			}
		});
	}



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * highlightSlider
	// *
	// *
	var $highlightSlider = $(".js-highlight-slider");

	if ($highlightSlider.length) {
		$highlightSlider.slick({
			fade: false,
			arrows: true,
			autoplay: true,
			speed: 1000,
			slidesToShow: 2,
			autoplaySpeed: 6000,
			prevArrow: $(".js-highlight-slider-nav-prev"),
			nextArrow: $(".js-highlight-slider-nav-next"),
			rows: 0,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 1,
						arrows: false,
					}
				}
			]
		});
	}

	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * highlightTeaser - filtering
	// *
	// *
	$(".js-highlight-filter-item").click(function() {
		var filter = $(this).data("filter");

		$(this).addClass("active").siblings().removeClass("active");

		$highlightSlider.slick('slickUnfilter');

		if (filter != 'all') {
			$highlightSlider.slick('slickFilter', filter);
		}
	});

	$( window ).on( "resize", function() {
		if ($(window).width() < 700) {
			$(".js-highlight-filter-item").removeClass("active");
		}
	});




	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * toggleContentSlider
	// *
	// *
	$(".js-toggle-content-slider-content-button").click(function () {
		$(this).parents(".js-toggle-content-slider-content-wrapper").find(".js-toggle-content-slider-content").slideToggle();
		$(this).parents(".js-toggle-content-slider-content-wrapper").find(".js-toggle-content-slider-content-button").toggleClass("toggleContentSlider__button--active");
	});

	var $toggleContentSlider = $(".js-toggle-content-slider");

	if ($toggleContentSlider.length) {
		$toggleContentSlider.each(function () {
			$(this).slick({
				fade: false,
				dots: true,
				arrows: true,
				autoplay: true,
				speed: 1000,
				autoplaySpeed: 8000,
				prevArrow: $(this).parents(".js-toggle-content-slider-wrapper").find(".js-toggle-content-slider-nav-prev"),
				nextArrow: $(this).parents(".js-toggle-content-slider-wrapper").find(".js-toggle-content-slider-nav-next"),
				responsive: [
					{
						breakpoint: 800,
						settings: {
							dots: false,
							arrows: false,
							autoplay: false,
						}
					}
				]
			});
		});
	}



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * heroSlider
	// *
	// *
	var $productCarousel = $(".js-product-carousel");

	if ($productCarousel.length) {
		$productCarousel.slick({
			dots: true,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 3,
			prevArrow: $(".js-product-carousel-nav-prev"),
			nextArrow: $(".js-product-carousel-nav-next"),
			responsive: [
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 900,
					settings: {
						dots: false,
					}
				},
				{
					breakpoint: 800,
					settings: {
						arrows: false,
						slidesToShow: 2,
						dots: false,
					}
				},
				{
					breakpoint: 650,
					settings: {
						arrows: false,
						slidesToShow: 1,
						dots: false,
					}
				}
			]
		});

		$(".js-product-carousel-filter").click(function () {
			var filter = $(this).data("filter");

			if (!$(this).hasClass("active")) {
				$productCarousel.slick('slickUnfilter');
				$productCarousel.slick('slickFilter', $(".js-filter-" + filter));
				$(this).addClass("active").siblings().removeClass("active");
			} else {
				$productCarousel.slick('slickUnfilter');
				$(this).removeClass("active");
			}
		});
	}


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * buttonToggle
	// *
	// *
	$(".js-button-toggle").click(function () {
		$(this).find(".js-button-toggle-label").toggleClass("active");
	});




	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * productFilter
	// *
	// *
	if ($(".js-product-filter-group").length) {
		$(".js-product-filter-group-button").click(function () {
			$(this).parents(".js-product-filter-group").toggleClass("hidden").find(".js-product-filter-group-list").slideToggle();
		});

		// hide all filter groups on init if noe filter is selected
		$(".js-product-filter-group:not(:first)").each(function () {
			if (!$(this).find("input:checked").length) {
				$(this).addClass("hidden").find(".js-product-filter-group-list").hide();
			}
		});

		$(".js-aside-filters input:not(.js-bookmark-product-button)").on("change", function () {
			$(this).parents("form").submit();
		});

		// toggle sortdir
		$(".js-sort-dir-button").click(function () {
			if ($(this).find("input").val() == "asc") {
				$(this).find("input").val("desc").trigger("change");
			} else {
				$(this).find("input").val("asc").trigger("change");
			}
		});
	}


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * sidebar toggle
	// *
	// *
	if ($(".js-product-catalog-sidebar-button").length) {
		$(".js-product-catalog-sidebar-button").click(function () {
			$(".js-product-catalog-sidebar").toggle();
			$(".js-product-catalog").toggleClass("hide-sidebar");
		});

		if ($(window).width() <= 1100) {
			$(".js-product-catalog-sidebar-button").trigger("click");
		}
	}


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * bookmark products
	// *
	// *
	if ($(".js-aside-bookmarks").length) {
		initAsideBookmarks();

		$(".js-bookmark-product-button").on("change", function () {
			initAsideBookmarks($(this).val());
			$(".js-aside-bookmarks:not(.asideBookmarks--active)").find(".js-aside-bookmarks-button").trigger("click");
		});

		function initAsideBookmarks($id) {

			if ($id != "") {
				var productid = $id;
			} else {
				var productid = '';
			}

			var language = $('html')[0].lang;

			$.ajax({
				url: "assets/templates/web/ajax/bookmark-products.ajax.php",
				data: { id: productid, language: language },
				datatype: 'application/json',
				method: 'get',
				success: function (data) {
					if (data.status == 'success') {

						$(".js-aside-bookmarks-body").html(data.output);
						$(".js-aside-bookmark-total").html(data.total);

						// cehck all market products
						$(".js-bookmark-product-button").prop('checked', false);
						$.each(data.products, function (index, value) {
							$("#bookmark-product-button-" + index).prop('checked', true);
						});

						// show aside button if not empty
						if (data.total >= 1) {
							$(".js-aside-bookmarks-button, .js-aside-inquiry-button").removeClass("hideEmpty");
						}

					} else if (data.status == 'error') {
						alert('Error');
					}
				},
				error: function () {
					alert('Error');
				}
			});


		}

		// remove items from bookmarks
		$(document).on("click", ".js-aside-bookmarks-remove-button", function (e) {
			var $id = $(this).data("product-id");
			initAsideBookmarks($id);
		});
	}





	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * inquiry products
	// *
	// *
	if ($(".js-aside-inquiry").length) {
		initAsideInquiry();

		$(document).on("click", ".js-inquiry-product-button", function () {
			initAsideInquiry($(this).data("product-id")); // add item to inquiry list
			$(".js-aside-inquiry:not(.asideBookmarks--active)").find(".js-aside-inquiry-button").trigger("click");
		});

		function initAsideInquiry($id) {

			if ($id != "") {
				var productid = $id;
			} else {
				var productid = '';
			}

			var language = $('html')[0].lang;

			$.ajax({
				url: "assets/templates/web/ajax/inquiry-products.ajax.php",
				data: { id: productid, language: language },
				datatype: 'application/json',
				method: 'get',
				success: function (data) {
					if (data.status == 'success') {

						$(".js-aside-inquiry-body").html(data.output);
						$(".js-aside-inquiry-total").html(data.total);

						// remove or add back product from/to bookmark list
						initAsideBookmarks(productid);

					} else if (data.status == 'error') {
						alert('Error');
					}
				},
				error: function () {
					alert('Error');
				}
			});
		}
	}



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * form Labels
	// *
	// *
	$(".js-form-field-input").on("change", function () {
		if (!$(this).val()) {
			$(this).parents(".js-form-field").removeClass("filled");
		} else {
			$(this).parents(".js-form-field").addClass("filled");
		}
	});

	// init
	$(".js-form-field-input").each(function () {
		if (!$(this).val()) {
			$(this).parents(".js-form-field").removeClass("filled");
		} else {
			$(this).parents(".js-form-field").addClass("filled");
		}
	});


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * form files
	// *
	// *
	$(".js-form-field-file").on("change", function () {
		var filename = $(this).val();
		$(this).parents(".js-form-field").find(".js-form-field-filename").val(filename);
	});


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * map
	// *
	// *
	$(".js-map-location-country-button").click(function () {
		if ($(this).parents(".js-map-location-country").hasClass("active")) {
			$(this).parents(".js-map-location-country")
				.removeClass("active")
				.find(".js-map-location-cities").slideUp()
				.find(".js-map-location-city").removeClass("active")
				.find(".js-map-location-city-content").slideUp();
		} else {
			$(this).parents(".js-map-location-country")
				.addClass("active")
				.siblings().removeClass("active")
				.find(".js-map-location-cities").slideUp()
				.find(".js-map-location-city").removeClass("active")
				.find(".js-map-location-city-content").slideUp();

			$(this).parents(".js-map-location-country")
				.find(".js-map-location-cities").slideDown();
		}
	});

	$(".js-map-location-city-button").click(function () {
		if ($(this).parents(".js-map-location-city").hasClass("active")) {
			$(this).parents(".js-map-location-city")
				.removeClass("active")
				.find(".js-map-location-city-content").slideUp();
		} else {
			$(this).parents(".js-map-location-city")
				.addClass("active")
				.siblings().removeClass("active")
				.find(".js-map-location-city-content").slideUp();

			$(this).parents(".js-map-location-city")
				.find(".js-map-location-city-content").slideDown();

			// center map to marker
			var markerid = $(this).data("map-marker-id");
			google.maps.event.trigger(markers[markerid], 'click');
		}
	});

	window.openCountryCityList = function (index) {
		var cityButton = $(".js-map-marker-id-" + index);

		if (!cityButton.parents(".js-map-location-city").hasClass("active")) {
			$(".js-map-location-country.active").find(".js-map-location-country-button").trigger("click");

			cityButton.trigger("click");
			cityButton.parents(".js-map-location-country").find(".js-map-location-country-button").trigger("click");
		}
	}

	if ($(".js-map-location-country").length) {
		var hash = window.location.hash.substring(1);
		if (hash) {
			$('.js-map-location-country-button[data-country=' + hash + ']').trigger("click");
		}
	}


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * timeline
	// *
	// *
	var $timeline = $(".js-timeline");

	if ($timeline.length) {
		$timeline.slick({
			fade: false,
			dots: true,
			prevArrow: $(".js-timeline-nav-prev"),
			nextArrow: $(".js-timeline-nav-next"),
			autoplay: false,
			variableWidth: true,
			infinite: false,
			centerMode: true,
			responsive: []
		});
	}



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * load more events
	// *
	// *
	var $events = $(".js-events-wrapper"),
		$eventsPagination = $(".js-events-pagination"),
		$eventsButton = $(".js-events-load-button");

	$eventsButton.click(function () {
		var url = $eventsPagination.find("a.next").attr("href");
		var $nextLink = $eventsPagination.find("a.next").removeClass("next").next("a").addClass("next");

		if (!$eventsPagination.find("a.next").length) {
			$eventsButton.fadeOut();
		}

		$.get(url).done(function (data) {
			$(data).find(".js-events-wrapper .js-events-block").appendTo($events);
		});
	});

	// mark next navigation page for load more button on init
	$eventsPagination.find("a.active").next("a").addClass("next");

	// hide button if not enough news on init
	if ($eventsPagination.find("a").length <= 1) {
		$eventsButton.hide();
	}


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * load more events
	// *
	// *
	$(".js-interactive-chart-link").click(function () {
		var id = $(this).data("interactive-chart-content-id");
		$(this).removeClass("activeSecondary").addClass("active").siblings().removeClass("active activeSecondary");
		$("#interactive-chart-content-" + id).show().siblings().hide();

		// group buttons
		var groupids = $(this).data("interactive-chart-group-ids")
		if (groupids) {
			var array = groupids.split(",");
			$.each(array, function (index, value) {
				$('[data-interactive-chart-content-id="' + value + '"]').addClass("active activeSecondary");
			});
		}
	});

	$(".js-interactive-chart-link").first().trigger("click");





	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * load more news
	// *
	// *
	var $blog = $(".js-blog-wrapper"),
		$blogPagination = $(".js-blog-pagination"),
		$blogButton = $(".js-blog-load-button");

	$blogButton.click(function () {
		var url = $blogPagination.find("a.next").attr("href");
		var $nextLink = $blogPagination.find("a.next").removeClass("next").next("a").addClass("next");

		if (!$blogPagination.find("a.next").length) {
			$blogButton.fadeOut();
		}

		$.get(url).done(function (data) {
			$(data).find(".js-blog-wrapper .js-blog").appendTo($blog);
		});
	});

	// mark next navigation page for load more button on init
	$blogPagination.find("a.active").next("a").addClass("next");

	// hide button if not enough news on init
	if ($blogPagination.find("a").length <= 1) {
		$blogButton.hide();
	}




	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * heroNavSlider
	// *
	// *
	var $heroNavSlider = $(".js-hero-nav-slider"),
		$heroNavSliderContent = $(".js-hero-nav-slider-content"),
		$heroNavSliderNav = $(".js-hero-nav-slider-nav");

	if ($heroNavSlider.length) {
		$heroNavSlider.slick({
			fade: false,
			dots: false,
			arrows: true,
			autoplay: true,
			speed: 1000,
			autoplaySpeed: 8000,
			prevArrow: $(".js-hero-nav-slider-nav-prev"),
			nextArrow: $(".js-hero-nav-slider-nav-next"),
			asNavFor: '.js-hero-nav-slider-content',
			appendDots: '.js-slick-dots',
			responsive: [
				{
					breakpoint: 1000,
					settings: {
						arrows: false,
						dots: true,
					}
				}
			]
		});

		$heroNavSliderContent.slick({
			fade: false,
			dots: false,
			arrows: false,
			autoplay: false,
			asNavFor: '.js-hero-nav-slider'
		});


		// navigate slider
		$(".js-hero-nav-slider-nav-item").click(function () {
			var index = $(this).index();
			$heroNavSlider.slick('slickGoTo', index);
		});

		// mark navigation element active if slider changes and move pointer
		$heroNavSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			$heroNavSliderNav.find(".js-hero-nav-slider-nav-item:eq(" + nextSlide + ")").addClass("active").siblings().removeClass("active");
		});

		// add active clas to first nav element
		$heroNavSliderNav.find(".js-hero-nav-slider-nav-item:first-child").addClass("active");
	}




	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * quoteSlider
	// *
	// *
	var $quoteSlider = $(".js-quote-slider");

	if ($quoteSlider.length) {
		$quoteSlider.each(function () {
			$(this).slick({
				fade: true,
				dots: true,
				arrows: true,
				autoplay: true,
				speed: 1000,
				autoplaySpeed: 8000,
				prevArrow: $(this).parents(".js-quote-slider-outer").find(".js-quote-slider-nav-prev"),
				nextArrow: $(this).parents(".js-quote-slider-outer").find(".js-quote-slider-nav-next"),
				appendDots: $(this).parents(".js-quote-slider-outer").find(".js-quote-slider-dots"),
				responsive: [
					{
						breakpoint: 1000,
						settings: {
							arrows: false,
						}
					}
				]
			});
		});
	}


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * eventSlider
	// *
	// *
	var $eventSlider = $(".js-eventslider");

	if ($eventSlider.length) {
		$eventSlider.each(function () {
			$(this).slick({
				fade: false,
				dots: true,
				arrows: true,
				autoplay: true,
				speed: 1000,
				autoplaySpeed: 8000,
				prevArrow: $(this).parents(".js-eventslider-wrapper").find(".js-eventslider-nav-prev"),
				nextArrow: $(this).parents(".js-eventslider-wrapper").find(".js-eventslider-nav-next"),
				appendDots: $(this).parents(".js-eventslider-wrapper"),
				rows: 0,
				responsive: [
					{
						breakpoint: 1000,
						settings: {
							arrows: false,
						}
					}
				]
			});
		});
	}


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * Fancybox click item
	// *
	// *
	$(".js-fancybox-item-parent").click(function () {
		if (!$(this).find(".js-consentfriend").is(":visible")) {
			$(this).find(".js-fancybox-item-link").fancybox().trigger("click");
		}
	});




	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * outdatetBrowser
	// *
	// *
	var outdatetBrowser = sessionStorage.getItem("outdatetBrowser");

	if (outdatetBrowser == 1) {
		$(".js-outdatet-browser").hide();
	}

	$(".js-outdatet-browser-button").click(function () {
		$(this).parents(".js-outdatet-browser").fadeOut();
		sessionStorage.setItem("outdatetBrowser", 1);
	});

	$(".js-outdatet-browser").removeClass("hidden");





	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * popUp
	// *
	// *
	if ($(".js-popup").length) {
		var popupid = $(".js-popup").data("popup-id"),
			popup = Cookies.get('popup-id-' + popupid);

		if (popup == 1) {
			$(".js-popup").hide();
		}

		$(".js-popup-close").click(function () {
			$(this).parents(".js-popup").fadeOut();
			Cookies.set('popup-id-' + popupid, '1', { expires: 365 });
		});

		$(".js-popup").removeClass("hidden");
	}


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * subNavSlider
	// *
	// *
	var $subNav = $(".js-sub-nav-slider");

	if ($subNav.length) {
		$subNav.slick({
			fade: true,
			dots: false,
			arrows: false,
			waitForAnimate: false,
			swipe: false,
			touchMove: false,
			responsive: []
		});
	}

	$(".js-nav-item").click(function (e) {

		// enable second click to go to url of the link
		if (!$(this).hasClass("open")) {
			e.preventDefault();
		}

		$(".js-sub-nav-overlay").fadeIn();
		$(".js-sub-nav").addClass("active");

		$(this).addClass("active open").siblings().removeClass("active open");
		var navIndex = $(this).index();
		$subNav.slick('slickGoTo', navIndex);
	});

	$(".js-sub-nav-overlay").click(function () {
		$(this).fadeOut();
		$(".js-sub-nav").removeClass("active");
		$(".js-nav-item").removeClass("open active");
		$(".js-nav-item.current").addClass("active");
	});

	$(".js-sub-nav-anchor").click(function () {
		$(".js-sub-nav-overlay").trigger("click");
	});



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * ie11 Fix SVG + opbject fit
	// *
	// *
	svg4everybody();
	objectFitImages();



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * quoteTextSlider
	// *
	// *
	var $quoteTextSlider = $(".js-quote-text-slider");

	if ($quoteTextSlider.length) {
		$quoteTextSlider.slick({
			fade: true,
			dots: true,
			arrows: true,
			autoplay: true,
			speed: 1000,
			autoplaySpeed: 8000,
			prevArrow: $(".js-quote-text-slider-nav-prev"),
			nextArrow: $(".js-quote-text-slider-nav-next"),
			appendDots: $(".js-quote-text-slider-outer").find(".js-quote-text-slider-dots"),
			responsive: [
				{
					breakpoint: 1000,
					settings: {
						arrows: false
					}
				}
			]
		});
	}


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * galleryCarousel
	// *
	// *
	var $galleryCarousel = $(".js-gallery-carousel");

	if ($galleryCarousel.length) {
		$galleryCarousel.each(function () {
			$(this).slick({
				fade: false,
				dots: false,
				infinite: true,
				slidesToShow: 3,
				arrows: true,
				autoplay: false,
				speed: 1000,
				prevArrow: $(this).parents(".js-gallery-carousel-wrapper").find(".js-gallery-carousel-nav-prev"),
				nextArrow: $(this).parents(".js-gallery-carousel-wrapper").find(".js-gallery-carousel-nav-next"),
				responsive: [
					{
						breakpoint: 1000,
						settings: {
							slidesToShow: 2,
						}
					},
					{
						breakpoint: 700,
						settings: {
							slidesToShow: 1,
						}
					}
				]
			});
		});
	}



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * filter (jobs)
	// *
	// *
	$(".js-filter-label").click(function () {
		$(this).parents(".js-filter").toggleClass("open");
	});

	$(".js-filter").on('mouseleave', function () {
		$(this).removeClass("open");
	});

	$(".js-filter-option").on("change", function () {
		var labels = [],
			$label = $(this).parents(".js-filter").find(".js-filter-label");

		$label.val('');

		$(this).parents(".js-filter-options").find(".js-filter-option:checked").each(function () {
			var title = $(this).data("title");
			labels.push(title);
			$label.val(labels.join(', '));
		});
	});

	$(".js-filter-option:checked").trigger("change");



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * form multiSelect
	// *
	// *
	$(".js-form-multiselect-input-label").click(function () {
		$(this).parents(".js-form-multiselect").toggleClass("open");
	});

	$(".js-form-multiselect").on('mouseleave', function () {
		$(this).removeClass("open");
	});

	$(".js-form-multiselect-option").on("change", function () {
		var labels = [],
			$fieldLabel = $(this).parents(".js-form-multiselect");
		$label = $(this).parents(".js-form-multiselect").find(".js-form-multiselect-input-label");

		$label.val('');
		$fieldLabel.removeClass('filled');

		$(this).parents(".js-form-multiselect-options").find(".js-form-multiselect-option:checked").each(function () {
			var title = $(this).data("title");
			labels.push(title);
			$label.val(labels.join(', '));
			$fieldLabel.addClass('filled');
		});
	});

	$(".js-form-multiselect-option:checked").trigger("change");



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * animateIn
	// *
	// *
	var offset = 60; /* Abstand vom Browserbottom, wenn Effekt einsetzen soll */

	function fadeInElements() {
		var viewPort = $(window).scrollTop() + $(window).height();

		$(".animateIn:visible").each(function () {
			var elementTop = $(this).offset().top;

			if ((elementTop + offset) <= viewPort) {
				var delay = $(this).data("animation-delay");
				$(this).css("transition-delay", delay + "s").addClass("animateIn--active");
			}
		});
	}
	$(window).scroll(function () {
		fadeInElements();
	});
	fadeInElements();



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * vPop
	// *
	// *
	if ($(".js-vpop").length) {

		var offset = 60; /* Abstand vom Browserbottom, wenn Effekt einsetzen soll */

		function fadeInVpopUp() {
			var viewPort = $(window).scrollTop() + $(window).height();

			$(".js-vpop-section:not(.closed):visible").each(function () {
				var elementTop = $(this).offset().top;

				if ((elementTop + offset) <= viewPort) {
					$(this).find(".js-vpop:not(.closed)").fadeIn();
				}
			});
		}

		$(window).scroll(function () {
			fadeInVpopUp();
		});

		fadeInVpopUp();


		$(".js-vpop-close").click(function () {
			$(this).parents(".js-vpop").addClass("closed").fadeOut();
		});
	}

	// trigger vpop via heroAction module
	$(".js-vpop-button").click(function (event) {
		event.preventDefault();
		var action = $(this).data("vpop-action");

		$(".js-vpop-onaction:first").fadeIn();

		if (action == 'email') {
			$(".js-callbackpop-tab:last").trigger("click");
		}
	});



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * product thumbs
	// *
	// *
	$(".js-product-thumb").click(function () {
		var url = $(this).data("image-url");
		$(".js-product-image").attr("src", url);
		$(this).addClass("active").siblings().removeClass("active");
	});


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * callBackPop
	// *
	// *
	$(".js-callbackpop-tab").click(function () {
		var index = $(this).index();
		$(".js-callbackpop-fieldset:eq(" + index + ")").show().siblings().hide();
	});

	if ($(".js-callbackpop-tab").length) {
		$(".js-callbackpop-tab:first").trigger("click");
	}

	// send form
	$("#js-callbackpop-form").submit(function (event) {
		event.preventDefault(); //prevent default action
		var post_url = $(this).attr("action"); //get form action url
		var request_method = $(this).attr("method"); //get form GET/POST method
		var form_data = $(this).serialize(); //Encode form elements for submission

		$(".js-form-error").removeClass("form__field--error");

		// validate form
		if (!$('#js-callbackpop-form input[name=privacy]:checked').length) {
			$(".js-form-error-privacy").addClass("form__field--error");
		}

		if ($('#js-callbackpop-form input[name=contact]:checked').val() == 'phone') {
			if ($('#js-callbackpop-form input[name=tel]').val() == "") {
				$(".js-form-error-tel").addClass("form__field--error");
			}
		} else {
			if ($('#js-callbackpop-form input[name=email]').val() == "") {
				$(".js-form-error-email").addClass("form__field--error");
			}
		}

		// send data
		if (!$("#js-callbackpop-form .form__field--error").length) {
			$.ajax({
				url: post_url,
				type: request_method,
				data: form_data
			}).done(function (response) {
				$("#js-callbackpop-form").hide();
				$("#js-callbackpop-success").fadeIn();
			});
		}
	});



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * videoImageSlider
	// *
	// *
	var $videoImageSlider = $(".js-videoimageslider");

	if ($videoImageSlider.length) {
		$videoImageSlider.each(function () {
			$(this).slick({
				fade: true,
				dots: false,
				infinite: true,
				arrows: true,
				autoplay: false,
				speed: 1000,
				touchMove: false,
				waitForAnimate: false,
				prevArrow: $(this).parents(".js-videoimageslider-wrapper").find(".js-videoimageslider-nav-prev"),
				nextArrow: $(this).parents(".js-videoimageslider-wrapper").find(".js-videoimageslider-nav-next"),
				responsive: [
					{
						breakpoint: 1150,
						settings: {
							arrows: false,
							dots: true
						}
					}
				]
			});
		});
	}


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * roundImge - Slider
	// *
	// *
	var $roundImageSlider = $(".js-roundimage-slider");

	if ($roundImageSlider.length) {
		$roundImageSlider.each(function () {
			$(this).slick({
				fade: false,
				dots: false,
				infinite: true,
				arrows: true,
				autoplay: false,
				speed: 1000,
				touchMove: false,
				waitForAnimate: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				rows: 0,
				prevArrow: $(this).parents(".js-roundimage-slider-wrapper").find(".js-roundimage-slider-nav-prev"),
				nextArrow: $(this).parents(".js-roundimage-slider-wrapper").find(".js-roundimage-slider-nav-next"),
				responsive: [
					{
						breakpoint: 1150,
						settings: {
							arrows: false,
							dots: true
						}
					},
					{
						breakpoint: 950,
						settings: {
							slidesToShow: 2,
							arrows: false,
							dots: true
						}
					},
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 1,
							arrows: false,
							dots: true
						}
					}
				]
			});
		});
	}

	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * topic - slider
	// *
	// *
	var $topicSlider = $(".js-topic-slider");


	if ($topicSlider.length) {
		$topicSlider.each(function () {
			$(this).slick({
				autoplay: false,
				fade: true,
				arrows: false,
				dots: true,
				speed: 1000,
				appendDots: $(".js-topic-slider-dots"),
				dotsClass: "filter",
				customPaging: function (slider, i) {
					var title = $(slider.$slides[i]).data("title");
					return title;
				}
			});
		});
	};


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * topic - filter
	// *
	// *
	var $topicFilter = $(".js-teaser-topic");

	if ($topicFilter.length) {

		$topicFilter.each(function () {

			$(this).find(".js-topic-accordion-item").each(function () {
				var textToCopy = $(this).text().trim();
				var listItem = $("<li>").text(textToCopy).addClass("js-topic-filter-value");
				$(".js-teaser-topic ul").append(listItem);
			})

			$(this).find(".js-topic-filter-value").on("click", function () {
				$(this).addClass("active").siblings().removeClass("active");
				index = $(this).index();
				$(this).parents(".js-topic-filter").find(".js-topic-filter-item").eq(index).addClass("active").siblings().removeClass("active");
			});

			if (window.matchMedia('(max-width: 1000px)').matches) {
				$(this).find(".js-topic-accordion-content").slideUp();

			}

			$(this).find(".js-topic-accordion-item").on("click", function () {
				$(this).toggleClass("active");
				$(this).siblings(".js-topic-accordion-content").slideToggle();
			});
		});

		$(".js-teaser-topic").find(".js-teaser-topic-button").clone().appendTo(".js-teaser-topic-target");
		$(this).find(".js-topic-filter-value:first-child").click();

	};
	$(window).on("load resize", function () {
		if (window.matchMedia('(min-width: 1000px)').matches) {
			$(".js-topic-accordion-content").slideDown();
		}
		else {
			$(".js-topic-accordion-content").slideUp();
		}
	});

	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * topic - icon
	// *
	// *
	$(".js-topic-icon").on("click touchStart mouseover", function () {
		$(this).addClass("active");
	});

	$(".js-topic-icon").on("mouseleave ", function () {
		$(this).removeClass("active");
	});


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * clickChart
	// *
	// *
	if ($(".js-clickchart").length) {
		$(".js-clickchart-item").click(function () {
			var $parent = $(this).parents(".js-clickchart"),
				index = $(this).index();

			$(this).addClass("active").siblings().removeClass("active");
			$parent.find(".js-clickchart-content-item").eq((index)).fadeIn().siblings().hide();
		});

		$(".js-clickchart-item")
			.mouseenter(function () {
				var $parent = $(this).parents(".js-clickchart"),
					index = $(this).index(),
					title = $parent.find(".js-clickchart-icon").eq((index)).data("title");

				$parent.find(".js-clickchart-title").html(title);
			})
			.mouseleave(function () {
				var $parent = $(this).parents(".js-clickchart"),
					index = $(this).index(),
					title = $parent.find(".js-clickchart-icon").eq((index)).data("title");

				$parent.find(".js-clickchart-title").html('');
			});

		$(".js-clickchart").each(function () {
			$(this).find(".js-clickchart-item").eq(0).trigger("click");
		});
	}



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * pricetable
	// *
	// *
	$(".js-pricetable-row")
		.mouseenter(function () {
			var row = $(this).index() + 1;
			$('.js-pricetable-row:nth-child(' + row + ')').addClass("active");
		})
		.mouseleave(function () {
			$(".js-pricetable-row").removeClass("active");
		});




	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * resource teaser
	// *
	// *
	$(".js-resource-teaser-button").click(function () {
		$(this).parents(".js-resource-teaser-outer").toggleClass("limit");
	});



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * step list hide elements
	// *
	// *
	$(".js-step-list-button").click(function () {
		$(this).parents(".js-step-list-wrapper").find(".js-step-list").toggleClass("stepList--showAll");
	});



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * location map
	// *
	// *
	$(".js-location-map-button").click(function () {
		$(this).toggleClass("active").parents(".js-location-map-selector").toggleClass("active").find(".js-location-map-wrapper").slideToggle();
	});

	$(".js-location-map-item").click(function () {
		$(this).addClass("active").siblings().removeClass("active");

		// center map to marker
		var markerid = $(this).data("map-marker-id");
		// google.maps.event.trigger(markers[markerid], 'click');

	});

	window.openLocationCityList = function (index) {
		var cityButton = $("[data-map-marker-id='" + index + "']"),
			$locationMapItem = $(".js-location-map-item"),
			$locationMapInfo = $("[data-location-map-info-id='" + index + "']");

		$locationMapInfo.addClass("active").siblings().removeClass("active");
	}

	// trigger first item
	window.triggerFirstLocation = function () {
		var $locationMapList = $(".js-location-map-wrapper");
		$locationMapList.find(".js-location-map-item:first").trigger("click");
	}

	if ($(".js-map")) {
		$(this).find(".js-map-location-country:first-child .js-map-location-country-button:first-child").trigger("click");
		$(this).find("[data-map-marker-id='1']").trigger("click");
	}




	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * heroVideo
	// *
	// *
	$(".js-herovideo-play").click(function () {
		$(this).parents(".js-herovideo").addClass("active");
	});



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * popUpLink
	// *
	// *
	$(".js-popuplink-close").click(function () {
		$(".js-popuplink").fadeOut();
	});

	$(".js-popuplink").each(function () {
		var $popUp = $(this),
			trigger = $(this).data("trigger");

		// remove potential other events on that item
		$("#" + trigger).removeClass("js-click-item-parent js-click-item-link").unbind();
		$("#" + trigger).parents().removeClass("js-click-item-parent js-click-item-link").unbind();

		// add trigger
		$("#" + trigger).click(function (event) {
			event.preventDefault();
			$popUp.fadeIn();
		});
	});


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * dropDown
	// *
	// *
	$(".js-dropdown-button").click(function () {
		$(this).parents(".js-dropdown").toggleClass("active").find(".js-dropdown-list").slideToggle();
	});


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * imageCompare
	// *
	// *
	$(".js-image-compare").twentytwenty();



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * contentTab
	// *
	// *
	$(".js-contenttab-header").each(function () {
		var tabLength = $(this).find(".js-contenttab-tab").length;
		$(this).addClass("contentTab--" + tabLength);
	});

	$(".js-contenttab-tab").click(function () {
		$(this).addClass("active").siblings(".js-contenttab-tab").removeClass("active");
		var index = $(this).index();

		$(this).parents(".js-contenttab-header").siblings(".js-contenttab").find(".js-contenttab-content").eq(index).show().addClass("active").siblings(".js-contenttab-content").hide();
	});

	$(".js-contenttab-tab").eq(0).trigger("click");


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * carousel
	// *
	// *
	// set height of carousel
	$(window).on("load resize", function () {
		var setHeight = 0;

		$(".js-carousel-item").each(function () {
			var height = $(this).outerHeight();

			if (height >= setHeight) {
				$(".js-carousel-wrapper").height(height);
				setHeight = height;
			}
		});
	});

	// pagination
	$(".js-carousel-nav-item-next").click(function () {
		slideRight();
	});

	$(".js-carousel-nav-item-prev").click(function () {
		slideLeft();
	});

});


$(window).on("load", function () {
	triggerFirstLocation();


});
